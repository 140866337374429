import classNames from 'classnames/bind';
import { useRef } from 'react';
import { useCallback, useEffect, useMemo } from 'react';

import { Button, Summary } from '~/components';
import { ENTER_KEY } from '~/constants';
import { useGame } from '~/contexts';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Property = ({ name = '', description = '', defaultValue = '', showHint = false, values = [] }) => {
    const { question } = useGame();

    return (
        <div className={cx('property')}>
            <code
                className={cx('name', {
                    disabled: showHint && !question?.labels?.includes(name),
                })}
            >
                {name}
            </code>
            <div className={cx('description')}>
                <div className={cx('text')} dangerouslySetInnerHTML={{ __html: description }} />
                <div className={cx('property-items')}>
                    {values.map((value, index) => (
                        <code key={index}>
                            {value} {value === defaultValue ? '(default)' : ''}
                        </code>
                    ))}
                </div>
            </div>
        </div>
    );
};

const Doc = ({ showHint = false }) => {
    return (
        <div className={cx('doc')}>
            <div className={cx('flex-container')}>
                <h4 className={cx('heading')}>Flex container</h4>
                <div className={cx('columns')}>
                    <div className={cx('column-item')}>
                        <Property
                            showHint={showHint}
                            name="justify-content"
                            description="Sắp xếp các hạng mục flex dọc theo trục chính."
                            defaultValue="flex-start"
                            values={[
                                'flex-start',
                                'flex-end',
                                'center',
                                'space-between',
                                'space-around',
                                'space-evenly',
                            ]}
                        />
                        <Property
                            showHint={showHint}
                            name="align-items"
                            description="Sắp xếp các hạng mục flex dọc theo trục ngang."
                            defaultValue="stretch"
                            values={['flex-start', 'flex-end', 'center', 'baseline', 'stretch']}
                        />
                        <Property
                            showHint={showHint}
                            name="align-content"
                            description="Sắp xếp các dòng trong hộp chứa khi có thêm không gian dọc theo trục ngang."
                            defaultValue="stretch"
                            values={[
                                'flex-start',
                                'flex-end',
                                'center',
                                'space-between',
                                'space-around',
                                'space-evenly',
                                'stretch',
                            ]}
                        />
                    </div>
                    <div className={cx('column-item')}>
                        <Property
                            showHint={showHint}
                            name="flex-direction"
                            description="Xác định hướng của trục chính."
                            defaultValue="row"
                            values={['row', 'row-reverse', 'column', 'column-reverse']}
                        />
                        <Property
                            showHint={showHint}
                            name="flex-wrap"
                            description="Xác định xem các hạng mục flex phải trên một dòng hoặc có thể quấn trên nhiều dòng."
                            defaultValue="nowrap"
                            values={['nowrap', 'wrap', 'wrap-reverse']}
                        />
                        <Property
                            showHint={showHint}
                            name="flex-flow"
                            description="<p>Viết tắt cho từ <code>flex-direction</code> và từ <code>flex-wrap</code>.</p>"
                            defaultValue=""
                            values={['<flex-direction> <flex-wrap>']}
                        />
                    </div>
                </div>
            </div>
            <div className={cx('flex-items')}>
                <h4 className={cx('heading')}>Flex items</h4>
                <div className={cx('columns')}>
                    <div className={cx('column-item')}>
                        <Property
                            showHint={showHint}
                            name="order"
                            description="Chỉ rõ thứ tự của các hạng mục flex."
                            values={['<itegeer>(... -1, 0 (default), 1, ...) ']}
                        />
                        <Property
                            showHint={showHint}
                            name="align-self"
                            description="Sắp xếp một hạng mục flex dọc theo trục ngang, ghi đè lên giá trị của <code>align-items</code>."
                            values={['flex-start', 'flex-end', 'center', 'baseline', 'stretch']}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

function Editor() {
    const { question, answer, isCorrect, showHint, finished, giveUp, shakeEditor, changeShowHint, changeAnswer, next } =
        useGame();

    const input = useRef(null);

    const officialAnswer = useMemo(() => {
        if (!question) {
            return '';
        }

        return question.answer;
    }, [question]);

    const line = useMemo(() => {
        if (!officialAnswer) {
            return 1;
        }

        return officialAnswer.split(';').filter((item) => item).length;
    }, [officialAnswer]);

    const handleShowHint = () => {
        input.current.focus();
        changeShowHint(true);
    };

    const handleGiveUp = () => {
        input.current.focus();
        giveUp();
    };

    const handleKeyDown = useCallback(
        (e) => {
            if (e.keyCode === ENTER_KEY && answer.split('\n').length === line) {
                next();
            }
        },
        [answer, line, next],
    );

    useEffect(() => {
        if (question) {
            input.current.focus();
        }
    }, [question]);

    if (finished) {
        return <Summary />;
    }

    return (
        <div className={cx('wrapper')}>
            <Doc showHint={showHint} />
            <div
                className={cx('editor', {
                    animated: shakeEditor,
                    shake: shakeEditor,
                })}
            >
                <div className={cx('line-numbers')}>
                    1 <br />
                    2 <br />
                    3 <br />
                    4 <br />
                    5 <br />
                    6 <br />
                    7 <br />
                    8 <br />
                    9 <br />
                    10
                </div>
                {question ? (
                    <>
                        <pre className={cx('before')}>{question.before}</pre>
                        <textarea
                            ref={input}
                            id="code"
                            className={cx('input')}
                            name="code"
                            value={answer}
                            style={{ '--height': `${24 * line}px` }}
                            autoFocus
                            onChange={(e) => {
                                const value = e.target.value;
                                const _line = value.split('\n').length;
                                if (_line <= line) {
                                    changeAnswer(value);
                                }
                            }}
                            onKeyDown={handleKeyDown}
                        ></textarea>
                        <pre className={cx('after')}>{question.after}</pre>
                    </>
                ) : (
                    <textarea
                        name="code"
                        id="code"
                        className={cx('input')}
                        style={{ '--height': `${24 * line}px` }}
                    ></textarea>
                )}
                <div className={cx('buttons-group')}>
                    <Button secondary onClick={showHint ? handleGiveUp : handleShowHint} className={cx('hint-button')}>
                        {showHint ? 'Điền đáp án' : 'Gợi Ý'}
                    </Button>
                    <Button className={cx('submit-button', { correct: isCorrect })} onClick={next}>
                        Kế Tiếp
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Editor;
