import { get } from './axiosClient';

const authApi = {
    getCurrentUser() {
        const url = '/auth/me?t=' + Date.now();
        return get(url);
    },
};

export default authApi;
