const randomInRange = (min, max) => Math.random() * (max - min) + min;

class LazyUI {
    constructor() {
        this._confetti = null;
    }

    getConfetti() {
        return new Promise((resolve) => {
            if (this._confetti) {
                return resolve(this._confetti);
            }

            import('canvas-confetti')
                .then((module) => {
                    if (module && module.default) {
                        this._confetti = module.default;
                        resolve(this._confetti);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    }

    firework({ startVelocity = 30, spread = 360, ticks = 100, zIndex = 0, duration = 15000 } = {}) {
        this.getConfetti().then((confetti) => {
            const animationEnd = Date.now() + duration;
            const defaults = { startVelocity, spread, ticks, zIndex };

            const interval = setInterval(function () {
                const timeLeft = animationEnd - Date.now();

                if (timeLeft <= 0) {
                    return clearInterval(interval);
                }

                const particleCount = 50 * (timeLeft / duration);
                // since particles fall down, start a bit higher than random
                confetti(
                    Object.assign({}, defaults, {
                        particleCount,
                        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
                    }),
                );
                confetti(
                    Object.assign({}, defaults, {
                        particleCount,
                        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
                    }),
                );
            }, 250);
        });
    }
}

export default new LazyUI();
