import { post, get } from './axiosClient';

const froggyApi = {
    createLog(body) {
        const url = '/froggy-pro-logs';
        return post(url, body);
    },
    getStats() {
        const url = '/froggy-pro-logs/stats';
        return get(url);
    },
};

export default froggyApi;
