import { random } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Cookie from 'js-cookie';

import { GameContext, useMedia } from '~/contexts';
import { convertCssCodeToKeyValue } from '~/helpers';
import { useDebounceEffect } from '~/hooks';
import { compareCoordinate } from '~/util';
import data from '~/util/data.json';
import { PondPickModal, SummaryModal } from '~/components';
import { froggyApi } from '~/api';
import { STATS_KEY, TURN_ID_KEY } from '~/constants';
import lazyUi from '~/helpers/lazy-ui';

const whiteListAnswers = [
    'justify-content',
    'flex-direction',
    'align-items',
    'flex-wrap',
    'align-content',
    'flex-flow',
    'order',
    'align-self',
];

function GameProvider({ children }) {
    const [settings, setSettings] = useState({
        muted: false,
        colorBlindMode: false,
    });
    const [pond, setPond] = useState(-1);
    const [currentLevel, setCurrentLevel] = useState(0);
    const [answer, setAnswer] = useState('');
    const [isCorrect, setIsCorrect] = useState(false);
    const [upgrading, setUpgrading] = useState(false);
    const [showHint, setShowHint] = useState(false);
    const [shakeEditor, setShakeEditor] = useState(false);
    const [finished, setFinished] = useState(false);
    const [verifying, setVerifying] = useState(false);
    const [stats, setStats] = useState(JSON.parse(window.localStorage.getItem(STATS_KEY) || 'null') || []);

    const {
        changeMuted,
        playHintSound,
        playCorrectSound,
        playBoingSound,
        playIncorrectSound,
        playWinSound,
        pauseWinSound,
    } = useMedia();

    const getQuestion = useCallback(() => {
        if (pond === -1) {
            return null;
        }
        const questions = data[pond].levels[currentLevel].questions;
        const randomIndex = random(0, questions.length - 1);
        return questions[randomIndex];
    }, [currentLevel, pond]);

    const [question, setQuestion] = useState(getQuestion());
    const [playedLevels, setPlayedLevels] = useState([]);
    const [shownHintLevels, setShowHintsLevels] = useState([]);
    const [giveUpLevels, setGiveUpLevels] = useState([]);
    const [playedQuestions, setPlayedQuestions] = useState([]);
    const [shownHintQuestions, setShowHintsQuestions] = useState([]);
    const [giveUpQuestions, setGiveUpQuestions] = useState([]);
    const [showPondPick, setShowPondPick] = useState(true);
    const [_giveUp, setGiveUp] = useState(false);

    const summaryModal = useRef(null);
    const changedAnswer = useRef(false);

    const levels = useMemo(() => {
        if (pond === -1) {
            return [];
        }
        return data[pond].levels;
    }, [pond]);

    const changeSettings = useCallback((options) => {
        setSettings(options);
    }, []);

    const restart = useCallback(() => {
        setPond(-1);
        setCurrentLevel(0);
        setShowHint(false);
        setGiveUp(false);
        setAnswer('');
        setIsCorrect(false);
        setPlayedLevels([]);
        setShowHintsLevels([]);
        setGiveUpLevels([]);
        setPlayedQuestions([]);
        setShowHintsQuestions([]);
        setGiveUpQuestions([]);
        setFinished(false);
        pauseWinSound();

        setShowPondPick(true);
    }, [pauseWinSound]);

    const changePond = useCallback(
        (pond) => {
            restart();
            setShowPondPick(false);
            setPond(pond);
        },
        [restart],
    );

    const changeAnswer = useCallback((value) => {
        setAnswer(value);
    }, []);

    const changeShowPondPick = useCallback((show) => {
        setShowPondPick(show);
    }, []);

    const changeShowHint = useCallback(
        (show) => {
            if (show) {
                setShowHintsLevels((prev) => [...prev, currentLevel]);
                playHintSound();
            }
            setShowHint(show);
        },
        [currentLevel, playHintSound],
    );

    const giveUp = useCallback(() => {
        if (showHint && answer !== question?.answer) {
            setGiveUpLevels((prev) => [...prev, currentLevel]);
            setGiveUp(true);
            setAnswer(question?.answer);
        }
    }, [answer, currentLevel, question?.answer, showHint]);

    const next = useCallback(() => {
        if (verifying) {
            return;
        }

        let isGivenUp = false;
        let isShownHint = false;
        if (_giveUp) {
            isGivenUp = true;
        } else if (showHint) {
            isShownHint = true;
        }

        if (answer.trim() && changedAnswer.current) {
            changedAnswer.current = false;
            froggyApi.createLog({
                turn_id: window.localStorage.getItem(TURN_ID_KEY),
                level: currentLevel + 1,
                answer,
                pond: pond + 1,
                question_id: question?.id,
                is_correct: isCorrect,
                is_given_up: isGivenUp,
                is_shown_hint: isShownHint,
            });
        }

        if (!isCorrect) {
            setShakeEditor(true);
            playIncorrectSound();
            return;
        }

        setUpgrading(true);
    }, [_giveUp, answer, currentLevel, isCorrect, playIncorrectSound, pond, question?.id, showHint, verifying]);

    const verifyAnswer = useCallback(() => {
        const frogs = document.querySelectorAll('.frog');
        const lilypads = document.querySelectorAll('.lilypad');

        const checkedIndexes = [];
        const isIntersected = [...frogs].every((frog) => {
            const rectFrog = frog.getBoundingClientRect();

            const frogColor = frog.classList[1];

            const lilypadIndex = [...lilypads].findIndex((lilypad, index) => {
                const lilypadColor = lilypad.classList[1];

                const rectLilypad = lilypad.getBoundingClientRect();

                return (
                    lilypadColor === frogColor && // Verify same color
                    !checkedIndexes.includes(index) && // Verify checked lilypad
                    compareCoordinate({ x1: rectFrog.x, y1: rectFrog.y }, { x2: rectLilypad.x, y2: rectLilypad.y }) && // Verify same coordinates
                    compareCoordinate(
                        { x1: rectFrog.x + rectFrog.width, y1: rectFrog.y + rectFrog.height },
                        { x2: rectLilypad.x + rectLilypad.width, y2: rectLilypad.y + rectLilypad.height },
                    )
                );
            });

            if (lilypadIndex !== -1) {
                checkedIndexes.push(lilypadIndex);
            }

            return lilypadIndex !== -1;
        });

        const useFlexProperties = whiteListAnswers.some((property) =>
            answer.toLowerCase().includes(property.toLowerCase()),
        );

        setVerifying(false);

        return isIntersected && useFlexProperties;
    }, [answer]);

    const value = useMemo(() => {
        return {
            settings,
            question,
            currentLevel,
            levels,
            playedLevels,
            giveUpLevels,
            shownHintLevels,
            playedQuestions,
            giveUpQuestions,
            shownHintQuestions,
            showPondPick,
            pond,
            answer,
            isCorrect,
            upgrading,
            finished,
            stats,
            showHint,
            shakeEditor,
            changeShowHint,
            changeAnswer,
            changeSettings,
            changePond,
            changeShowPondPick,
            next,
            restart,
            giveUp,
        };
    }, [
        answer,
        changeAnswer,
        changePond,
        changeSettings,
        changeShowHint,
        changeShowPondPick,
        currentLevel,
        finished,
        giveUp,
        giveUpLevels,
        giveUpQuestions,
        isCorrect,
        levels,
        next,
        playedLevels,
        playedQuestions,
        pond,
        question,
        restart,
        settings,
        shakeEditor,
        showHint,
        showPondPick,
        shownHintLevels,
        shownHintQuestions,
        stats,
        upgrading,
    ]);

    useEffect(() => {
        setQuestion(getQuestion());
    }, [getQuestion]);

    // Init styles for pond
    useEffect(() => {
        if (question) {
            const { before, after } = question;
            const style = `${before + after}`;

            const cssMap = convertCssCodeToKeyValue(style);

            const pond = document.querySelector('#pond');
            const reds = document.querySelectorAll('.frog.r');
            const greens = document.querySelectorAll('.frog.g');
            const yellows = document.querySelectorAll('.frog.y');

            Object.keys(cssMap).forEach((key) => {
                if (key === '#pond' && pond) {
                    pond.style = cssMap[key];
                } else if (key === '.red' && reds) {
                    reds.forEach((red) => {
                        red.style = cssMap[key];
                    });
                } else if (key === '.yellow' && yellows) {
                    yellows.forEach((yellow) => {
                        yellow.style = cssMap[key];
                    });
                } else if (key === '.green' && greens) {
                    greens.forEach((green) => {
                        green.style = cssMap[key];
                    });
                }
            });
        }
    }, [question]);

    // Update styles for pond
    useDebounceEffect(
        () => {
            if (question) {
                const { before, after } = question;
                const style = `${before + answer + after}`;

                const cssMap = convertCssCodeToKeyValue(style);

                const pond = document.querySelector('#pond');
                const reds = document.querySelectorAll('.frog.r');
                const greens = document.querySelectorAll('.frog.g');
                const yellows = document.querySelectorAll('.frog.y');

                Object.keys(cssMap).forEach((key) => {
                    if (key === '#pond' && pond) {
                        pond.style = cssMap[key];
                    } else if (key === '.red' && reds) {
                        reds.forEach((red) => {
                            red.style = cssMap[key];
                        });
                    } else if (key === '.yellow' && yellows) {
                        yellows.forEach((yellow) => {
                            yellow.style = cssMap[key];
                        });
                    } else if (key === '.green' && greens) {
                        greens.forEach((green) => {
                            green.style = cssMap[key];
                        });
                    }
                });

                setIsCorrect(verifyAnswer());
            }
        },
        500,
        [answer, question, verifyAnswer],
    );

    useEffect(() => {
        setVerifying(true);
        changedAnswer.current = true;
    }, [answer]);

    // Update styles for background
    useEffect(() => {
        if (question) {
            const { before, answer, after } = question;
            const style = `${before + answer + after}`;

            const cssMap = convertCssCodeToKeyValue(style);

            const background = document.querySelector('#background');
            const reds = document.querySelectorAll('.lilypad.r');
            const greens = document.querySelectorAll('.lilypad.g');
            const yellows = document.querySelectorAll('.lilypad.y');

            Object.keys(cssMap).forEach((key) => {
                if (key === '#pond' && background) {
                    background.style = cssMap[key];
                } else if (key === '.red' && reds) {
                    reds.forEach((red) => {
                        red.style = cssMap[key];
                    });
                } else if (key === '.yellow' && yellows) {
                    yellows.forEach((yellow) => {
                        yellow.style = cssMap[key];
                    });
                } else if (key === '.green' && greens) {
                    greens.forEach((green) => {
                        green.style = cssMap[key];
                    });
                }
            });
        }
    }, [question]);

    // Handle next level
    useEffect(() => {
        let resetTimer;
        let soundTimer;
        if (upgrading) {
            resetTimer = setTimeout(() => {
                if (_giveUp) {
                    setGiveUpQuestions((prev) => [...prev, question]);
                } else if (showHint) {
                    setShowHintsQuestions((prev) => [...prev, question]);
                } else {
                    setPlayedLevels((prev) => [...prev, currentLevel]);
                    setPlayedQuestions((prev) => [...prev, question]);
                }

                if (currentLevel < 19) {
                    setCurrentLevel(currentLevel + 1);
                } else {
                    setFinished(true);
                }
                setUpgrading(false);
                setAnswer('');
                setShowHint(false);
                setGiveUp(false);
            }, 2000);

            soundTimer = setTimeout(() => {
                playBoingSound();
            }, 1100);
        }

        return () => {
            clearTimeout(resetTimer);
            clearTimeout(soundTimer);
        };
    }, [_giveUp, currentLevel, playBoingSound, question, showHint, upgrading]);

    // Show popup on reload
    useEffect(() => {
        const handle = (e) => {
            e.preventDefault();
            if (Number(Cookie.get('logined')) !== 0) {
                return (e.returnValue = 'Are you sure you want to leave?');
            }
        };

        if (currentLevel > 0 || answer) {
            window.addEventListener('beforeunload', handle);
        }

        return () => window.removeEventListener('beforeunload', handle);
    }, [answer, currentLevel]);

    useEffect(() => {
        let timer;
        if (shakeEditor) {
            timer = setTimeout(() => {
                setShakeEditor(false);
            }, 1000);
        }

        return () => clearTimeout(timer);
    }, [shakeEditor]);

    // Set muted sound on changed muted mode
    useEffect(() => {
        changeMuted(settings.muted);
    }, [settings.muted, changeMuted]);

    // Play win sound on finished
    useEffect(() => {
        if (finished) {
            summaryModal.current.show();
            playWinSound();
        }
    }, [finished, playWinSound]);

    // Play correct sound when answer is correct
    useEffect(() => {
        if (isCorrect) {
            playCorrectSound();
        }
    }, [isCorrect, playCorrectSound]);

    // Get stats
    useEffect(() => {
        if (showPondPick) {
            froggyApi.getStats().then((res) => {
                setStats(() => {
                    const stats = data.map((pond, index) => {
                        const totalLevels = pond.levels.length;
                        const resPond = res.data.find((item) => item.pond === index + 1);
                        if (!resPond) {
                            return 0;
                        }

                        return Math.floor((resPond.count / totalLevels) * 100);
                    });
                    window.localStorage.setItem(STATS_KEY, JSON.stringify(stats));
                    return stats;
                });
            });
        }
    }, [showPondPick]);

    useEffect(() => {
        if (finished) {
            lazyUi.firework();
        }
    }, [finished]);

    return (
        <GameContext.Provider value={value}>
            {children}
            {showPondPick && <PondPickModal />}
            <SummaryModal ref={summaryModal} />
        </GameContext.Provider>
    );
}

export default GameProvider;
