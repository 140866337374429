import { useCallback, useMemo, useRef } from 'react';

import sounds from '~/assets/sounds';
import { MediaContext } from '~/contexts';

function MediaProvider({ children }) {
    const boingSound = useRef(null);
    const correctSound = useRef(null);
    const hintSound = useRef(null);
    const incorrectSound = useRef(null);
    const winSound = useRef(null);

    const changeMuted = useCallback((muted) => {
        boingSound.current.muted = muted;
        correctSound.current.muted = muted;
        hintSound.current.muted = muted;
        incorrectSound.current.muted = muted;
        winSound.current.muted = muted;
    }, []);

    const playBoingSound = useCallback(() => {
        boingSound.current.currentTime = 0;
        boingSound.current.play();
    }, []);

    const playCorrectSound = useCallback(() => {
        correctSound.current.currentTime = 0;
        correctSound.current.play();
    }, []);

    const playHintSound = useCallback(() => {
        hintSound.current.currentTime = 0;
        hintSound.current.play();
    }, []);

    const playIncorrectSound = useCallback(() => {
        incorrectSound.current.currentTime = 0;
        incorrectSound.current.play();
    }, []);

    const playWinSound = useCallback(() => {
        winSound.current.currentTime = 0;
        winSound.current.play();
    }, []);

    const pauseWinSound = useCallback(() => {
        winSound.current.currentTime = 0;
        winSound.current.pause();
    }, []);

    const value = useMemo(
        () => ({
            playBoingSound,
            playCorrectSound,
            playHintSound,
            playIncorrectSound,
            playWinSound,
            pauseWinSound,
            changeMuted,
        }),
        [playBoingSound, playCorrectSound, playHintSound, playIncorrectSound, playWinSound, pauseWinSound, changeMuted],
    );

    return (
        <MediaContext.Provider value={value}>
            {children}
            <audio src={sounds.boing} ref={boingSound} />
            <audio src={sounds.correct} ref={correctSound} />
            <audio src={sounds.hint} ref={hintSound} />
            <audio src={sounds.incorrect} ref={incorrectSound} />
            <audio src={sounds.win} ref={winSound} loop />

            <img src="/images/frog-green-blind.svg" className="preload" alt="" />
            <img src="/images/frog-green.svg" className="preload" alt="" />
            <img src="/images/frog-red-blind.svg" className="preload" alt="" />
            <img src="/images/frog-red.svg" className="preload" alt="" />
            <img src="/images/frog-yellow-blind.svg" className="preload" alt="" />
            <img src="/images/frog-yellow.svg" className="preload" alt="" />
            <img src="/images/lilypad-green.svg" className="preload" alt="" />
            <img src="/images/lilypad-red-blind.svg" className="preload" alt="" />
            <img src="/images/lilypad-red.svg" className="preload" alt="" />
            <img src="/images/lilypad-yellow-blind.svg" className="preload" alt="" />
            <img src="/images/lilypad-yellow.svg" className="preload" alt="" />
        </MediaContext.Provider>
    );
}

export default MediaProvider;
