import { PASSED, PASSED_WITH_HINT, SKIPPED } from '~/constants';

export const convertCssCodeToKeyValue = (code) => {
    const array = code
        .split(/(\{)|(\})/)
        .filter((item) => item)
        .map((item) => item.trim())
        .filter((item) => item && item !== '{' && item !== '}');

    const obj = {};

    array.forEach((item, index) => {
        if (index % 2 === 0) {
            const css = array[index + 1];
            if (css) {
                obj[item] = css
                    .split('')
                    .filter((item) => item)
                    .join('');
            } else {
                obj[item] = '';
            }
        }
    });

    return obj;
};

export const getSummary = (playedQuestions = [], giveUpQuestions = [], shownHintQuestions = []) => {
    const result = [];

    playedQuestions.forEach((question) => {
        question.labels.forEach((label) => {
            const index = result.findIndex((item) => item.label === label);

            if (index === -1) {
                result.push({
                    label,
                    types: [
                        {
                            type: PASSED,
                            number: 1,
                        },
                        {
                            type: PASSED_WITH_HINT,
                            number: 0,
                        },
                        {
                            type: SKIPPED,
                            number: 0,
                        },
                    ],
                });
            } else {
                result[index].types[0].number += 1;
            }
        });
    });

    giveUpQuestions.forEach((question) => {
        question.labels.forEach((label) => {
            const index = result.findIndex((item) => item.label === label);

            if (index === -1) {
                result.push({
                    label,
                    types: [
                        {
                            type: PASSED,
                            number: 0,
                        },
                        {
                            type: PASSED_WITH_HINT,
                            number: 0,
                        },
                        {
                            type: SKIPPED,
                            number: 1,
                        },
                    ],
                });
            } else {
                result[index].types[2].number += 1;
            }
        });
    });

    shownHintQuestions.forEach((question) => {
        question.labels.forEach((label) => {
            const index = result.findIndex((item) => item.label === label);

            if (index === -1) {
                result.push({
                    label,
                    types: [
                        {
                            type: PASSED,
                            number: 0,
                        },
                        {
                            type: PASSED_WITH_HINT,
                            number: 1,
                        },
                        {
                            type: SKIPPED,
                            number: 0,
                        },
                    ],
                });
            } else {
                result[index].types[1].number += 1;
            }
        });
    });

    return result;
};
