import classNames from 'classnames/bind';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useGame } from '~/contexts';

import styles from './SummaryModal.module.scss';

const cx = classNames.bind(styles);

function SummaryModal(props, ref) {
    const [isShow, setIsShow] = useState(false);

    const { playedQuestions, shownHintQuestions } = useGame();

    const medal = useMemo(() => {
        const passedCount = playedQuestions.length + shownHintQuestions.length;
        if (passedCount >= 8) {
            return 'gold';
        }
        if (passedCount >= 4) {
            return 'silver';
        }
        if (passedCount >= 1) {
            return 'bronze';
        }
    }, [playedQuestions, shownHintQuestions]);

    const hide = useCallback(() => {
        setIsShow(false);
    }, []);

    const show = useCallback(() => {
        setIsShow(true);
    }, []);

    useImperativeHandle(ref, () => ({
        show,
        hide,
    }));

    useEffect(() => {
        if (show) {
            window.addEventListener('keydown', hide);
        } else {
            window.removeEventListener('keydown', hide);
        }
    }, [hide, show]);

    return (
        <div className={cx('wrapper', { show: isShow, hide: !isShow })}>
            <div className={cx('overlay')} onClick={hide}></div>
            <div className={cx('main')}>
                <div className={cx('header')}>
                    <button className={cx('close-btn')} onClick={hide}>
                        ×
                    </button>
                </div>
                <div className={cx('body')}>
                    <div>
                        <span className={cx('badge', 'flexbox-froggy-pro', medal)} />
                    </div>
                    <h3 className={cx('title')}>Flexbox Froggy Pro</h3>
                    <p className={cx('sub-title')}>
                        Xin chúc mừng! Khi mà bạn đã đến được đây, chính là bạn đã chinh phục được lũ "cóc" này!!!
                    </p>
                    <div className={cx('dots')}>
                        <span className={cx('dot', 'bronze')}>1</span>
                        <span className={cx('dot', 'silver')}>4</span>
                        <span className={cx('dot', 'gold')}>8</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default forwardRef(SummaryModal);
