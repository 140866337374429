import { useEffect, useMemo, useState } from 'react';

import { authApi } from '~/api';
import { Dialog } from '~/components';
import { USER_KEY } from '~/constants';
import { AuthContext } from '~/contexts';

function AuthProvider({ children }) {
    const persistedUser = JSON.parse(window.localStorage.getItem(USER_KEY) || 'null');

    const [currentUser, setCurrentUser] = useState(persistedUser);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        authApi
            .getCurrentUser()
            .then((res) => {
                window.localStorage.setItem(USER_KEY, JSON.stringify(res.data));
                setCurrentUser(res.data);
            })
            .catch(() => {
                window.localStorage.removeItem(USER_KEY);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const value = useMemo(() => ({ currentUser }), [currentUser]);

    if (!currentUser) {
        return null;
    }

    if (!currentUser.is_pro && !isLoading) {
        return <Dialog />;
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
