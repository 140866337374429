import classNames from 'classnames/bind';

import { useGame } from '~/contexts';
import CheckBox from '../CheckBox';

import styles from './SettingMenu.module.scss';

const cx = classNames.bind(styles);

function SettingMenu() {
    const {
        settings: { muted, colorBlindMode },
        changeSettings,
    } = useGame();

    const changeColorBlindMode = () =>
        changeSettings({
            muted,
            colorBlindMode: !colorBlindMode,
        });

    const changeMuted = () =>
        changeSettings({
            muted: !muted,
            colorBlindMode,
        });

    return (
        <div className={cx('wrapper')}>
            <CheckBox label="Chế độ mù màu" id="color-blind" checked={colorBlindMode} onChange={changeColorBlindMode} />
            <CheckBox label="Tắt âm thanh" id="muted" checked={muted} onChange={changeMuted} />
        </div>
    );
}

export default SettingMenu;
