let config = {
    api: {
        baseUrl: 'https://api-gateway.f8team.local2/api',
    },
    routes: {
        root: 'https://f8team.local2',
        login: 'https://accounts.f8team.local2/login',
        logout: 'https://accounts.f8team.local2/logout',
        register: 'https://accounts.f8team.local2/register',
        flashcard: 'https://flash.f8team.local2',
    },
};

const isLocal = () => window.location.hostname.endsWith('f8team.local2');

const isStaging = () => window.location.hostname.endsWith('f8team.dev');

const isProduction = () => window.location.hostname.endsWith('fullstack.edu.vn');

if (isLocal()) {
    config = {
        api: {
            baseUrl: 'https://api-gateway.f8team.local2/api',
        },
        routes: {
            root: 'https://f8team.local2',
            login: 'https://accounts.f8team.local2/login',
            logout: 'https://accounts.f8team.local2/logout',
            register: 'https://accounts.f8team.local2/register',
            flashcard: 'https://flash.f8team.local2',
        },
    };
} else if (isStaging()) {
    config = {
        api: {
            baseUrl: 'https://api-gateway.f8team.dev/api',
        },
        routes: {
            root: 'https://f8team.dev',
            login: 'https://accounts.f8team.dev/login',
            logout: 'https://accounts.f8team.dev/logout',
            register: 'https://accounts.f8team.dev/register',
            flashcard: 'https://flash.f8team.dev',
        },
    };
} else if (isProduction()) {
    config = {
        api: {
            baseUrl: 'https://api-gateway.fullstack.edu.vn/api',
        },
        routes: {
            root: 'https://fullstack.edu.vn',
            login: 'https://accounts.fullstack.edu.vn/login',
            logout: 'https://accounts.fullstack.edu.vn/logout',
            register: 'https://accounts.fullstack.edu.vn/register',
            flashcard: 'https://flash.fullstack.edu.vn',
        },
    };
}

export default config;
