import classNames from 'classnames/bind';
import { useRef } from 'react';
import { v4 as uuid } from 'uuid';

import styles from './CheckBox.module.scss';

const cx = classNames.bind(styles);

function CheckBox({ label, ...props }) {
    const tmpId = useRef(uuid());

    return (
        <div className={cx('wrapper')}>
            <input type="checkbox" {...props} id={props.id || tmpId.current} />
            {!!label && <label htmlFor={props.id || tmpId.current}>{label}</label>}
        </div>
    );
}

export default CheckBox;
