import classNames from 'classnames/bind';
import { Fragment, useMemo } from 'react';

import { Button } from '~/components';
import { PASSED, PASSED_WITH_HINT, SKIPPED } from '~/constants';
import { useGame } from '~/contexts';
import { getSummary } from '~/helpers';

import styles from './Summary.module.scss';

const cx = classNames.bind(styles);

const BreakdownItem = ({ number = 0, type = '' }) => {
    const [label, borderColor] = useMemo(() => {
        switch (type) {
            case PASSED:
                return ['Solved without hints', '#228b24'];
            case PASSED_WITH_HINT:
                return ['Solved with hints', '#c4c229'];
            case SKIPPED:
                return ['Skipped', '#cc1d21'];
            default:
                return ['', 'transparent'];
        }
    }, [type]);

    return (
        <div className={cx('breakdown-item')} style={{ '--border-color': borderColor }}>
            <div className={cx('number')}>{number}</div>
            <div className={cx('label')}>{label}</div>
        </div>
    );
};

function Summary() {
    const { playedQuestions, giveUpQuestions, shownHintQuestions, changeShowPondPick } = useGame();

    const ratio = useMemo(
        () => getSummary(playedQuestions, giveUpQuestions, shownHintQuestions),
        [giveUpQuestions, playedQuestions, shownHintQuestions],
    );

    const score = Math.floor(
        ((playedQuestions.length + shownHintQuestions.length) /
            (playedQuestions.length + shownHintQuestions.length + giveUpQuestions.length)) *
            100,
    );

    return (
        <div className={cx('wrapper')}>
            <p>Bạn đã hoàn thành trò chơi. Dưới đây là bảng tóm tắt kết quả.</p>
            <div className={cx('body')}>
                <div className={cx('scores')}>
                    <span>{score}</span>%
                </div>
                <div className={cx('breakdown')}>
                    <BreakdownItem number={playedQuestions.length} type={PASSED} />
                    <BreakdownItem number={shownHintQuestions.length} type={PASSED_WITH_HINT} />
                    <BreakdownItem number={giveUpQuestions.length} type={SKIPPED} />
                </div>
                <div className={cx('ratio')}>
                    {ratio.map((item) => {
                        return (
                            <Fragment key={item.label}>
                                <div className={cx('label')}>{item.label}</div>
                                <div className={cx('percents')}>
                                    {item.types.map((item, index, types) => {
                                        const total = types.reduce((prev, current) => prev + current.number, 0);

                                        const percent = item.number / total;

                                        let color = '';
                                        switch (item.type) {
                                            case PASSED:
                                                color = '#228b24';
                                                break;
                                            case PASSED_WITH_HINT:
                                                color = '#c4c229';
                                                break;
                                            case SKIPPED:
                                                color = '#cc1d21';
                                                break;
                                            default:
                                        }

                                        return (
                                            <span
                                                key={index}
                                                style={{ '--flex': `${percent}`, '--color': color }}
                                            ></span>
                                        );
                                    })}
                                </div>
                            </Fragment>
                        );
                    })}
                </div>
            </div>
            <p>
                Mỗi lần bạn chơi lại Pond này, bạn sẽ nhận được các level hoàn toàn mới, vì vậy hãy ghé qua bất cứ lúc
                nào để luyện tập thêm. Các pond khác sẽ có rất nhiều thử thách mới. Để kiếm được nhiều huy chương, bạn
                hãy hoàn thành mỗi Pond một cách tốt nhất nhé.
            </p>
            <div className={cx('footer')}>
                <Button onClick={() => changeShowPondPick(true)}>Chơi lại</Button>
            </div>
        </div>
    );
}

export default Summary;
