import { Home } from '~/pages';
import { Header } from '~/components';

function App() {
    return (
        <div className="App">
            <Header />
            <Home />
        </div>
    );
}

export default App;
