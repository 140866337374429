import classNames from 'classnames/bind';

import { useGame } from '~/contexts';

import styles from './LevelMenu.module.scss';

const cx = classNames.bind(styles);

function LevelMenu({ onClose = () => {} }) {
    const { levels, currentLevel, playedLevels, shownHintLevels, giveUpLevels, restart } = useGame();

    const handleRestart = () => {
        const isConfirmed = window.confirm(
            'Bạn có chắc chắn là bạn muốn thiết lập lại trò chơi không? Toàn bộ tiến trình của bạn sẽ bị mất và bạn sẽ được chuyển đến đầu của trò chơi.',
        );
        if (isConfirmed) {
            restart();
            onClose();
        }
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('levels')}>
                {levels.map((level, index) => (
                    <span
                        key={`level-${index}`}
                        className={cx('level-item', {
                            current: currentLevel === index,
                            passed: playedLevels.includes(index),
                            'shown-hint': shownHintLevels.includes(index),
                            'give-up': giveUpLevels.includes(index),
                        })}
                    >
                        {index + 1}
                    </span>
                ))}
            </div>
            <button className={cx('restart-button')} onClick={handleRestart}>
                Restart
            </button>
        </div>
    );
}

export default LevelMenu;
