export const isMac = () => navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i);

export const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');

    for (const cookie of cookies) {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
};

export const compareCoordinate = ({ x1, y1 } = {}, { x2, y2 } = {}) => {
    return Math.abs(x1 - x2) < 2 && Math.abs(y1 - y2) < 2;
};
