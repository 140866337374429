import classNames from 'classnames/bind';

import styles from './Button.module.scss';

const cx = classNames.bind(styles);

function Button({ children = null, primary, secondary, className, disabled, onClick = () => {}, ...props }) {
    const Component = props.href ? 'a' : 'button';

    return (
        <Component
            className={cx('wrapper', className, { disabled, primary: primary || (!primary && !secondary), secondary })}
            onClick={onClick}
            {...props}
        >
            {children}
        </Component>
    );
}

export default Button;
