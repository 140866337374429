import { faCaretDown, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';

import { Editor, LevelMenu, SettingMenu } from '~/components';
import { useGame } from '~/contexts';

import styles from './Sidebar.module.scss';

const cx = classNames.bind(styles);

function Sidebar() {
    const [showSettingsMenu, setShowSettingsMenu] = useState(false);
    const [showLevelMenu, setShowLevelMenu] = useState(false);

    const { pond, currentLevel, levels } = useGame();

    const handleShowSettingsMenu = () => {
        setShowSettingsMenu(true);
    };

    const handleHideSettingsMenu = () => {
        setShowSettingsMenu(false);
    };

    const handleShowLevelMenu = () => {
        setShowLevelMenu(true);
    };

    const handleHideLevelMenu = () => {
        setShowLevelMenu(false);
    };

    useEffect(() => {
        const handle = (e) => {
            const clickedOutside = !e.target.matches(`.${cx('settings-wrapper')} *`);

            if (clickedOutside) {
                handleHideSettingsMenu();
            }
        };

        window.addEventListener('click', handle);

        return () => window.removeEventListener('click', handle);
    }, []);

    useEffect(() => {
        const handle = (e) => {
            const clickedOutside = !e.target.matches(`.${cx('level-wrapper')} *`);

            if (clickedOutside) {
                handleHideLevelMenu();
            }
        };

        window.addEventListener('click', handle);

        return () => window.removeEventListener('click', handle);
    }, []);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('header')}>
                <h1 className={cx('title')}>Flexbox Froggy Pro</h1>
                <div className={cx('controls')}>
                    <div className={cx('settings-wrapper')}>
                        <button
                            className={cx('settings', 'glass-button')}
                            onClick={showSettingsMenu ? handleHideSettingsMenu : handleShowSettingsMenu}
                        >
                            <FontAwesomeIcon icon={faGear} />
                        </button>
                        {showSettingsMenu && <SettingMenu />}
                    </div>
                    <div className={cx('level-wrapper')}>
                        <button
                            className={cx('level-indicator', 'glass-button')}
                            onClick={showLevelMenu ? handleHideLevelMenu : handleShowLevelMenu}
                        >
                            <span className={cx('pond')}>Pond {pond + 1}</span>
                            <span className={cx('level')}>
                                {currentLevel + 1} của {levels.length}{' '}
                                <FontAwesomeIcon icon={faCaretDown} className={cx('icon')} />
                            </span>
                        </button>
                        {showLevelMenu && <LevelMenu onClose={handleHideLevelMenu} />}
                    </div>
                </div>
            </div>
            <div className={cx('editor')}>
                <Editor />
            </div>
        </div>
    );
}

export default Sidebar;
