import classNames from 'classnames/bind';
import { useMemo } from 'react';
import { useGame } from '~/contexts';

import styles from './View.module.scss';

const cx = classNames.bind(styles);

const Pond = () => {
    const {
        question,
        upgrading,
        finished,
        settings: { colorBlindMode },
    } = useGame();

    const board = useMemo(() => {
        if (!question) {
            return [];
        }

        return question.board.split('');
    }, [question]);

    if (!question) {
        return <div className={cx('pond')}></div>;
    }

    if (finished) {
        return (
            <div className={cx('pond', 'finished')}>
                {'gyrgyrgyrgyrgyrgyrgyrgyrg'.split('').map((item, index) => (
                    <div key={index} className={`frog ${item}`}>
                        <div className={cx('bg', 'bounce', 'animated', 'infinite', { blind: colorBlindMode })}></div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div key={question.id} className={cx('pond')} id="pond">
            {board.map((item, index) => (
                <div
                    key={index}
                    className={cx('frog', item, {
                        bounceOutUp: upgrading,
                        animated: upgrading,
                    })}
                >
                    <div
                        className={cx('bg', 'animated', 'pulse', 'infinite', { blind: colorBlindMode })}
                        style={styles[index]}
                    ></div>
                </div>
            ))}
        </div>
    );
};

const Background = () => {
    const {
        question,
        finished,
        settings: { colorBlindMode },
    } = useGame();

    const board = useMemo(() => {
        if (!question) {
            return [];
        }

        return question.board.split('');
    }, [question]);

    const styles = useMemo(() => {
        return board.map(() => ({
            transform: `rotate(${360 * Math.random()}deg) scale(${1 + (Math.random() / 5 - 0.2)})`,
        }));
    }, [board]);

    const finishedStyles = useMemo(() => {
        return 'gyrgyrgyrgyrgyrgyrgyrgyrg'.split('').map(() => ({
            transform: `rotate(${360 * Math.random()}deg) scale(${1 + (Math.random() / 5 - 0.2)})`,
        }));
    }, []);

    if (!question) {
        return <div className={cx('background')}></div>;
    }

    if (finished) {
        return (
            <div className={cx('background', 'finished')}>
                {'gyrgyrgyrgyrgyrgyrgyrgyrg'.split('').map((item, index) => (
                    <div key={index} className={`lilypad ${item}`}>
                        <div className={cx('bg', { blind: colorBlindMode })} style={finishedStyles[index]}></div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div key={question.id} className={cx('background')} id="background">
            {board.map((item, index) => (
                <div key={index} className={`lilypad ${item}`}>
                    <div className={cx('bg', { blind: colorBlindMode })} style={styles[index]}></div>
                </div>
            ))}
        </div>
    );
};

function View() {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('board')}>
                <Pond />
                <Background />
            </div>
        </div>
    );
}

export default View;
