import axios from 'axios';
import config from '~/config';

const httpRequest = axios.create({
    baseURL: config.api.baseUrl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve();
        }
    });

    failedQueue = [];
};

const refreshToken = async () => {
    try {
        await post('/auth/refresh');
        processQueue(null);
    } catch (error) {
        processQueue(error);
        throw error;
    }
};

const getNewToken = async () => {
    if (!isRefreshing) {
        isRefreshing = true;
        await refreshToken();
        isRefreshing = false;
        return;
    }

    // Return a promise that resolves with the new token
    return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
    });
};

// Handle refresh token
httpRequest.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        const shouldRenewToken =
            error.response.status === 401 &&
            !originalRequest._retry &&
            error.response.headers['x-refresh-token-required'] === 'true';

        if (shouldRenewToken) {
            originalRequest._retry = true;
            try {
                await getNewToken();
                return httpRequest(originalRequest);
            } catch (error) {
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    },
);

const send = async (method, path, data, options = {}) => {
    const isPutOrPatch = ['put', 'patch'].includes(method.toLowerCase());
    const effectiveMethod = isPutOrPatch ? 'post' : method;
    const effectivePath = isPutOrPatch ? `${path}${path.includes('?') ? '&' : '?'}_method=${method}` : path;

    const response = await httpRequest({
        method: effectiveMethod,
        url: effectivePath,
        data,
        ...options,
    });

    return response.data;
};

export const get = async (path, options = {}) => {
    return await send('get', path, null, options);
};

export const post = async (path, data, options = {}) => {
    return await send('post', path, data, options);
};

export const put = async (path, data, options = {}) => {
    return await send('put', path, data, options);
};

export const patch = async (path, data, options = {}) => {
    return await send('patch', path, data, options);
};

export const del = async (path, options = {}) => {
    return await send('delete', path, options);
};

export default httpRequest;
