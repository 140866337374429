import 'requestidlecallback-polyfill';
import '~/helpers/cookieStoreProlyfill';
import '~/helpers/eventTargetPolyfill';

import ReactDOM from 'react-dom/client';
import { v4 as uuid } from 'uuid';

import App from '~/App';
import { GlobalStyles } from '~/components';
import config from '~/config';
import { TURN_ID_KEY } from '~/constants';
import { AuthProvider, GameProvider, MediaProvider } from '~/contexts/providers';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <GlobalStyles>
        <MediaProvider>
            <AuthProvider>
                <GameProvider>
                    <App />
                </GameProvider>
            </AuthProvider>
        </MediaProvider>
    </GlobalStyles>,
);

// Automatically logout from all open tabs when user logout (same a browser)
window.cookieStore.addEventListener('change', ({ changed }) => {
    for (const { name, value } of changed) {
        if (name === 'logined') {
            // eslint-disable-next-line default-case
            switch (value) {
                case '0':
                    window.location.href = `${config.routes.login}?continue=${encodeURIComponent(
                        window.location.href,
                    )}`;
                    break;
                case '1':
                    window.location.reload();
                    break;
            }
        }
    }
});

(() => {
    window.localStorage.setItem(TURN_ID_KEY, uuid());
})();
