import classNames from 'classnames/bind';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createPortal } from 'react-dom';

import styles from './PondPickModal.module.scss';
import { useGame } from '~/contexts';

const cx = classNames.bind(styles);

const Star = ({ percent = 0 }) => {
    return (
        <div className={cx('rating')} style={{ '--percent': `${percent}%` }}>
            <div className={cx('color-stars')}>
                <FontAwesomeIcon className={cx('star')} icon={faStar} />
                <FontAwesomeIcon className={cx('star')} icon={faStar} />
                <FontAwesomeIcon className={cx('star')} icon={faStar} />
                <FontAwesomeIcon className={cx('star')} icon={faStar} />
            </div>
            <div className={cx('empty-stars')}>
                <FontAwesomeIcon className={cx('star')} icon={faStar} />
                <FontAwesomeIcon className={cx('star')} icon={faStar} />
                <FontAwesomeIcon className={cx('star')} icon={faStar} />
                <FontAwesomeIcon className={cx('star')} icon={faStar} />
            </div>
        </div>
    );
};

const PondItem = ({ percent, title = '', index = 0, onClick = () => {} }) => {
    return (
        <div className={cx('pond-item')} onClick={onClick}>
            <div className={cx('index')}>
                {index}
                <img src="/images/frog-green.svg" alt="" />
            </div>
            <div className={cx('info')}>
                <p className={cx('title')}>{title}</p>
                <div className={cx('star')}>
                    <Star percent={percent} />
                </div>
            </div>
        </div>
    );
};

function PondPickModal() {
    const { changePond, stats } = useGame();

    return createPortal(
        <div className={cx('wrapper')}>
            <div className={cx('main')}>
                <div className={cx('body')}>
                    <h3 className={cx('title')}>Pick a pond</h3>
                    <div className={cx('ponds-list')}>
                        <div className={cx('left')}>
                            <PondItem
                                title="Starting line"
                                percent={stats[0]}
                                index={1}
                                onClick={() => changePond(0)}
                            />
                            <PondItem title="Wrapped up" percent={stats[1]} index={2} onClick={() => changePond(1)} />
                            <PondItem title="Misaligned" percent={stats[2]} index={3} onClick={() => changePond(2)} />
                            <PondItem title="Out of order" percent={stats[3]} index={4} onClick={() => changePond(3)} />
                        </div>
                        <div className={cx('right')}>
                            <PondItem title="Shallow end" percent={stats[4]} index={5} onClick={() => changePond(4)} />
                            <PondItem title="Mixed bag" percent={stats[5]} index={6} onClick={() => changePond(5)} />
                            <PondItem title="Total mayhem" percent={stats[6]} index={7} onClick={() => changePond(6)} />
                            <PondItem
                                title="Whole shebang"
                                percent={stats[7]}
                                index={8}
                                onClick={() => changePond(7)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.querySelector('body'),
    );
}

export default PondPickModal;
