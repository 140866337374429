import classNames from 'classnames/bind';

import config from '~/config';
import { Button } from '~/components';

import styles from './Dialog.module.scss';

const cx = classNames.bind(styles);

function Dialog() {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('main')}>
                <div className={cx('header')}>Thông báo</div>
                <p className={cx('body')}>
                    Trò chơi này chỉ dành cho học viên của khóa học <br />
                    <a href={`${config.routes.root}/landing/htmlcss`}>HTML/CSS PRO</a>
                </p>
                <Button href={`${config.routes.root}/landing/htmlcss`} className={cx('see-more-btn')}>
                    Tìm hiểu thêm
                </Button>
            </div>
        </div>
    );
}

export default Dialog;
