import classNames from 'classnames/bind';

import { Sidebar, View } from '~/components';

import styles from './Home.module.scss';

const cx = classNames.bind(styles);

function Home() {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('main')}>
                <Sidebar />
                <View />
            </div>
        </div>
    );
}

export default Home;
