// Local storage keys
export const USER_KEY = 'user';
export const TURN_ID_KEY = 'turn_id';
export const STATS_KEY = 'stats';

// Key codes
export const ENTER_KEY = 13;

// Summary types
export const PASSED = 'passed';
export const SKIPPED = 'skipped';
export const PASSED_WITH_HINT = 'passed_with_hint';
