import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';

import images from '~/assets/images';
import config from '~/config';
import { useAuth } from '~/contexts';

import styles from './Header.module.scss';

const cx = classNames.bind(styles);

function Header() {
    const [avatarUrl, setAvatarUrl] = useState(null);

    const { currentUser } = useAuth();

    useEffect(() => {
        setAvatarUrl(currentUser?.avatar_url ? currentUser?.avatar_url : images.fallbackAvatar);
    }, [currentUser?.avatar_url]);

    return (
        <div className={cx('wrapper')}>
            <a className={cx('logo')} href={config.routes.root}>
                <img src={images.logo} alt="F8 - Học lập trình để đi làm" />
                <span>Học Lập Trình Để Đi Làm</span>
            </a>
            <div className={cx('current-user')}>
                <a href={`${config.routes.root}/@${currentUser.username}`} target="_blank" rel="noreferrer">
                    <img
                        src={avatarUrl}
                        alt={currentUser.full_name}
                        onError={() => setAvatarUrl(images.fallbackAvatar)}
                    />
                </a>
            </div>
        </div>
    );
}

export default Header;
